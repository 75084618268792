document.addEventListener("DOMContentLoaded", () => {
    // Section 1: Play button
    const videoHTML =
        '<lite-vimeo class="w-full h-auto rounded shadow-md" videoid="843882106"></lite-vimeo>';
    const modal = new tingle.modal({
        closeMethods: ["overlay", "button", "escape"],
        closeLabel: "Cerrar",
        beforeClose: () => {
            // Before the modal is closed, you have to close the video too (so that the audio doesn't stay playing)
            modal.setContent(videoHTML);

            // Process to close the modal.
            return true;
        },
    });
    modal.setContent(videoHTML);

    const playButton = document.getElementById("play-button");
    playButton.addEventListener("click", () => modal.open());

    // Section 2: Companies list
    tns({
        container: "#companies",
        responsive: {
            0: {
                items: 2,
            },
            640: {
                items: 4,
            },
            1024: {
                items: 6,
            },
        },
        speed: 300,
        autoplay: true,
        autoplayHoverPause: false,
        autoplayTimeout: 2500,
        autoplayButtonOutput: false,
        mouseDrag: false,
        nav: false,
        controls: false,
        center: false,
    });

    // Section 11: Contact form.
    const contactForm = document.getElementById("contact-me-form");

    contactForm.addEventListener("submit", (e) => {
        e.preventDefault();

        // Disable the submit button.
        const submitBtn = contactForm.querySelector("button[type=submit]");
        submitBtn.disabled = true;
        submitBtn.style.background = "#b3b3b3";
        submitBtn.style.opacity = "0.5";

        // Make the HTTP request to the API.
        fetch("/api/contact-me", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                fullName: contactForm.querySelector("[name=full-name]").value,
                email: contactForm.querySelector("[name=email]").value,
                phone: contactForm.querySelector("[name=phone]").value,
                typeOfLead: contactForm.querySelector("[name=type-of-lead]")
                    .value,
            }),
        })
            .then((response) => response.text())
            .then((text) => {
                // Enable the button again.
                submitBtn.disabled = false;
                submitBtn.style.background = "#C40018";
                submitBtn.style.opacity = "1";

                // Show the response to the user.
                Toastify({
                    text,
                    gravity: "top",
                    position: "center",
                    style: {
                        background: "#16a34a", // Green 600
                    },
                }).showToast();
            });
    });
});
